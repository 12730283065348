








import { Component, Prop, Vue } from "vue-property-decorator";
import VModal from "vue-js-modal";

Vue.use(VModal);
@Component
export default class ViewerPdf extends Vue {
  @Prop()
  private data!: Iterable<number>;

  @Prop()
  private attachmentName!: string;

  get embeddableContent(): string {
    return `data:application/pdf;base64,${btoa(
      new Uint8Array(this.data).reduce(
        (d, byte) => d + String.fromCharCode(byte),
        ""
      )
    )}`;
  }

  get modalName() {
    return `htmlAttachmentPreview-${this.attachmentName}`;
  }

  mounted() {
    this.$modal.show(this.modalName);
  }

  hideModal() {
    this.$modal.hide(this.modalName);
  }
}
